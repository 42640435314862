import React from 'react';
import Img from 'gatsby-image';
import { ArrowLink } from 'components/Shared/Link/Link';
import './BundleHowItWorksInfoCard.scss';

const BundleHowItWorksInfoCard = ({ data }) => (
  <div className='bundle-how-it-works-info-card'>
    <div className="row">
      <div
        className='col-12 col-lg-6 bundle-how-it-works-info-card__image-container'
      >
        <Img fluid={data.image.childImageSharp.fluid} />
      </div>
      <div className='col-12 col-lg-5 d-flex'>
        <div
          className="bundle-how-it-works-info-card__title-container"
        >
          <h3 className="bundle-how-it-works-info-card__title">{data.title}</h3>
          <div
            className="bundle-how-it-works-info-card__description"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
          {data.link && (
            <div className="bundle-how-it-works-info-card__link-container">
              <ArrowLink className="bundle-how-it-works-info-card__link" to={data.link.url}>
                {data.link.text}
              </ArrowLink>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default BundleHowItWorksInfoCard;
