import React, { Component } from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import classnames from 'classnames';
import Img from 'gatsby-image';
import { ArrowLink } from 'components/Shared/Link/Link';
import ZipContext from 'components/Shared/ZipContext';
import './HowItWorks.scss';

class HowItWorks extends Component {
  constructor() {
    super();
    this.state = {
      highlightedIndex: 0,
    };
    this.scheduler = null;
  }

  componentDidMount() {
    const areaData = this.props.zipContext.fullAccess
      ? this.props.data.serviceArea
      : this.props.data.nonServiceArea;
    this.scheduler = setInterval(() => {
      this.setState(prevState => ({
        highlightedIndex:
          (prevState.highlightedIndex + 1) % areaData.items.length,
      }));
    }, 3000);
  }

  componentWillUnmount() {
    if (this.scheduler) {
      clearInterval(this.scheduler);
    }
  }

  render() {
    const { data } = this.props;
    const { highlightedIndex } = this.state;
    const areaData = this.props.zipContext.fullAccess
      ? data.serviceArea
      : data.nonServiceArea;
    return (
      <div id="howitworks" className="aaa-howitworks">
        <Img
          fluid={areaData.heroImage.childImageSharp.fluid}
          className="aaa-howitworks__image"
        />
        <ContentBox>
          <div className="row">
            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-6 col-xl-5 offset-xl-7">
              <div className="aaa-howitworks__heading">
                <h2 className="aaa-howitworks__title">{areaData.title}</h2>
                <div className="aaa-howitworks__subtitle">
                  {areaData.subtitle}
                </div>
              </div>
              <ul className="aaa-howitworks__items-list">
                {areaData.items.map((item, i, arr) => {
                  const active = i === highlightedIndex;
                  return (
                    <li key={i} className="row aaa-howitworks__item">
                      <div className="col-2 d-flex flex-column">
                        <div
                          className={classnames('aaa-howitworks__square', {
                            'aaa-howitworks__square--active': active,
                          })}
                        >
                          {i + 1}
                        </div>
                        {i < arr.length - 1 && (
                          <div className="aaa-howitworks__line" />
                        )}
                      </div>
                      <div className="col-10">
                        <div className="aaa-howitworks__item-container">
                          <div
                            className={classnames(
                              'aaa-howitworks__item-title',
                              {
                                'aaa-howitworks__item-title--active': active,
                              }
                            )}
                          >
                            {item.title}
                          </div>
                          <div
                            className={classnames(
                              'aaa-howitworks__item-description',
                              {
                                'aaa-howitworks__item-description--active': active,
                              }
                            )}
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                          {item.link && (
                            <ArrowLink
                              className="aaa-howitworks__link"
                              to={item.link.url}
                            >
                              {item.link.text}
                            </ArrowLink>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </ContentBox>
      </div>
    );
  }
}

class HowItWorksWithContext extends Component {
  render() {
    return (
      <ZipContext.Consumer>
        {context1 => <HowItWorks {...this.props} zipContext={context1} />}
      </ZipContext.Consumer>
    );
  }
}

export default HowItWorksWithContext;
