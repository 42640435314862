import React from 'react';
import './BundleHowItWorksItem.scss';

const BundleHowItWorksItem = ({ data }) => (
  <div className='bundle-how-it-works-item'>
    <div className='bundle-how-it-works-item__icon-container'>
      <img
        className="bundle-how-it-works-item__icon-image"
        src={data.image.publicURL}
      />
    </div>
    <div className='bundle-how-it-works-item__container'>
      {data.preTitle && <div className='bundle-how-it-works-item__container-pre-title'>{data.preTitle}</div>}
      <div className='bundle-how-it-works-item__container-title'>{data.title}</div>
      <div
        className='bundle-how-it-works-item__container-description'
        dangerouslySetInnerHTML={{__html: data.description}}
      />
    </div>
  </div>
);

export default BundleHowItWorksItem;
