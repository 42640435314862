import React from 'react';
import classnames from 'classnames';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import './ComparisonChart.scss';
import { ArrowLink } from 'components/Shared/Link/Link';
import { LinkArrowButtonPrimary } from 'components/Shared/Button/Button';

const features = [
  ({ plan, index }) => {
    const additionalClasses = [
      'aaa-comparison__package-title-blue',
      'aaa-comparison__package-title-red',
      'aaa-comparison__package-title-average',
    ];
    return (
      <div
        className={classnames('aaa-comparison__package-title', {
          [additionalClasses[index]]: true,
        })}
      >
        {plan.title}
      </div>
    );
  },
  ({ plan }) => (
    <div className="aaa-comparison__package-subtitle">{plan.subtitle}</div>
  ),
  ({ plan }) => (
    <div className="aaa-comparison__package-price">{plan.price}</div>
  ),
  ({ plan }) => (
    <div className="aaa-comparison__package-period">{plan.billingPeriod}</div>
  ),
  ({ plan }) => (
    <div className="aaa-comparison__package-description">
      {plan.description}
    </div>
  ),
  ({ plan }) => (
    <div className="aaa-comparison__package-price-description">
      {plan.priceDescription}
    </div>
  ),
];

const ComparisonChart = ({ data }) => {
  const lastPlan = data.plans[data.plans.length - 1];

  return (
    <div id="comparison-chart" className="aaa-comparison">
      <ContentBox>
        <div className="row no-gutters">
          <div className="col-12 col-lg-5 d-flex">
            <div className="aaa-comparison__title-container">
              <h2 className="aaa-comparison__title">{data.title}</h2>
              <div
                className="aaa-comparison__description"
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
              <div
                className="aaa-comparison__disclaimer"
                dangerouslySetInnerHTML={{ __html: data.disclaimer }} 
              />
              <div className="aaa-comparison__link-container">
                <ArrowLink className="aaa-comparison__link" to={data.link.url}>
                  {data.link.text}
                </ArrowLink>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-7">
            <div className="aaa-comparison__plans-container">
              <div className="row">
                <div className="aaa-comparison__logo-container">
                  <img
                    src={data.logo.publicURL}
                    className="aaa-comparison__logo"
                  />
                </div>
              </div>
              {features.map((feature, i) => (
                <div key={i} className="row">
                  {data.plans.map((plan, j, arr) => (
                    <div
                      key={j}
                      className={classnames({
                        'aaa-comparison__col': j < arr.length - 1,
                        'aaa-comparison__last-col': j === arr.length - 1,
                      })}
                    >
                      {feature({ plan, index: j })}
                    </div>
                  ))}
                </div>
              ))}
              <div className="row">
                <div className="aaa-comparison__button-container">
                  <LinkArrowButtonPrimary
                    className="aaa-comparison__button"
                    to={data.button.url}
                  >
                    {data.button.text}
                  </LinkArrowButtonPrimary>
                </div>
              </div>
              <div className="aaa-comparison__box" />
              <div className="aaa-comparison__box-grey">
                <div className="aaa-comparison__box-grey__inner" />
              </div>
            </div>
          </div>
          <div className="col-12 d-block d-sm-none">
            <div className="aaa-comparison__average-container">
              <div className="aaa-comparison__package-title aaa-comparison__package-title-average">
                {lastPlan.title}
              </div>
              <div className="aaa-comparison__package-subtitle">
                {lastPlan.subtitle}
              </div>
              <div className="aaa-comparison__package-price-average">
                {lastPlan.price}
              </div>
              <div className="aaa-comparison__package-period">
                {lastPlan.billingPeriod}
              </div>
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
  );
};

export default ComparisonChart;
