import React, {Fragment} from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import CTAForm from 'components/CTAForm';
import { HowItWorks } from 'components/HowItWorks';
import ComparisonChart from 'components/ComparisonChart/ComparisonChart';
import InfoBlock, { IconsBlock, InfoContainer } from 'components/InfoBlock';
import InfoCardBig from 'components/InfoCardBig/InfoCardBig';
import TestimonialsContainer from 'components/TestimonialsContainer/TestimonialsContainer';
import ScrollToTopButton from 'components/ScrollToTopButton';
import ZipContext from 'components/Shared/ZipContext';
import { truncateString } from 'components/Shared/Utils';

const desiredPostsOrder = [
  "/blog/alarm-monitoring-basics",
  "/blog/home-security-systems-and-how-they-work",
  "/blog/11-reasons-to-install-a-home-security-system"
]

const IndexPage = ({ data, location }) => {
  const relatedPosts = data.relatedPosts.edges
    .sort(
      (a, b) =>
        desiredPostsOrder.indexOf(a.node.frontmatter.path) -
        desiredPostsOrder.indexOf(b.node.frontmatter.path)
    )
    .map(edge => ({
      title: edge.node.frontmatter.title,
      description: truncateString(edge.node.frontmatter.description),
      image: edge.node.frontmatter.image,
      link: {
        text: 'Read More',
        url: edge.node.frontmatter.path,
      },
    }));
  return (
    <Layout location={location} type="standard">
      <SEO meta={data.indexYaml.metaTags} title={data.indexYaml.title} titleTemplate="%s" />
      <Helmet
        defer={false}
        meta={[
          {
            class: "swiftype",
            name: "title",
            "data-type": "string",
            content: data.indexYaml.swiftype.title,
          },
          {
            class: "swiftype",
            name: "teaser",
            "data-type": "string",
            content: data.indexYaml.swiftype.description,
          },
          {
            class: "swiftype",
            name: "teaser_image_path",
            "data-type": "enum",
            content: `${data.site.siteMetadata.siteUrl}${data.indexYaml.swiftype.image.childImageSharp.fixed.src}`,
          },
          {
            class: "swiftype",
            name: "page_type",
            "data-type": "string",
            content: data.indexYaml.swiftype.page_type,
          },
          {
            class: "swiftype",
            name: "category",
            "data-type": "string",
            content: data.indexYaml.swiftype.category,
          },
        ]}
      />
      <Hero data={data.indexYaml.hero} />
      <HowItWorks data={data.indexYaml.howItWorks} />
      <InfoContainer data={data.indexYaml.whyAAA}>
        <InfoBlock data={data.indexYaml.whyAAA} nobg={true} />
        <IconsBlock data={data.indexYaml.iconsBlock} nobg={true} />
      </InfoContainer>
      <ZipContext.Consumer>
        {zipContext => {
          let bigInfoCards, compareSolutions; 
          if(zipContext.fullAccess) {
            bigInfoCards = data.indexYaml.bigInfoCards.serviceArea;
            compareSolutions = data.indexYaml.compareSolutionsBlock.serviceArea;
          } else {
            bigInfoCards = data.indexYaml.bigInfoCards.nonServiceArea;
            compareSolutions = data.indexYaml.compareSolutionsBlock.nonServiceArea;
          }
          return (
            <Fragment>
              <InfoBlock data={compareSolutions} />
              <ComparisonChart data={data.indexYaml.comparisonChart} />
              {bigInfoCards.map((card, i) => (<InfoCardBig key={i} data={card} />))}
            </Fragment>
          );
        }}        
      </ZipContext.Consumer>
      <hr />
      <InfoBlock
        data={{ ...data.indexYaml.relatedPosts, infoCards: relatedPosts }}
      />
      <TestimonialsContainer />
      <CTAForm />
      <ZipGateContainer location={location} />
      <ScrollToTopButton />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    indexYaml {
      id
      title
      metaTags {
        name
        content
      }
      swiftype {
        title
        description
        image {
          childImageSharp {
            fixed(width: 250, height: 131) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        page_type
        category
      }
      hero {
        size
        title
        subtitle
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroImagePosition
        primaryButton {
          text
          url
        }
        secondaryButton {
          text
          url
        }
      }
      howItWorks {
        serviceArea {
          title
          subtitle
          heroImage {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          items {
            title
            description
            link {
              text
              url
            }
          }
        }
        nonServiceArea {
          title
          subtitle
          heroImage {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          items {
            title
            description
            link {
              text
              url
            }
          }
        }
      }
      whyAAA {
        title
        infoCards {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 535, maxHeight: 405) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        disclaimers
      }
      iconsBlock {
        icons {
          text
          icon
        }
      }
      compareSolutionsBlock {
        serviceArea {
          title
          infoCards {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 535, maxHeight: 405) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            link {
              text
              url
            }
          }
        }
        nonServiceArea {
          title
          infoCards {
            title
            subtitle
            description
            image {
              childImageSharp {
                fluid(maxWidth: 535, maxHeight: 405) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            link {
              text
              url
            }
          }
        }
      }
      comparisonChart {
        title
        description
        link {
          text
          url
        }
        button {
          text
          url
        }
        logo {
          publicURL
        }
        plans {
          id
          title
          titleShort
          subtitle
          price
          billingPeriod
          priceDescription
          description
        }
        disclaimer
      }
      relatedPosts {
        title
      }

      bigInfoCards {
        serviceArea {
          color
          type
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 891, maxHeight: 521) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageLink
        }
        nonServiceArea {
          color
          type
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 891, maxHeight: 521) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageLink
        }
      }
    }

    relatedPosts: allMdx(
      filter: {
        frontmatter: {
          path: {
            in: [
              "/blog/alarm-monitoring-basics",
              "/blog/home-security-systems-and-how-they-work",
              "/blog/11-reasons-to-install-a-home-security-system"
            ]
          }
        }
      },
      sort: {fields: frontmatter___date, order: DESC},
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            date(formatString: "MMMM Do, YYYY")
            title
            image: previewImage {
              childImageSharp {
                fluid(maxWidth: 535, maxHeight: 405, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            description
          }
        }
      }
    }
  }
`;
